import { DocumentSnapshot } from '../firestoreImports.js';

export class Company {
	constructor(public code: string,
				public title: string,
				public signUpCodes: string[],
				public signUpDomains: string[],
				public locations: string[],
				public passwordEnabled: boolean,
				public ssoProviderId?: string
		) {}

	static fromData(doc: DocumentSnapshot): Company | null {
		if (!doc.data()) {
            console.warn("Cannot create Company from snapshot")
            return null
        }
		const data = doc.data()!
		const title = data.title ?? doc.id
		const signUpCodes = data.signUpCodes ?? []
		const signUpDomains = data.signUpDomains ?? []
		const locations = data.locations ?? []
		const passwordEnabled = data.passwordEnabled ?? true
		const ssoProviderId = data.ssoProviderId
		return new Company(doc.id, title, signUpCodes, signUpDomains, locations, passwordEnabled, ssoProviderId)
	}
}