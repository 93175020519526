import { DocumentSnapshot, Timestamp } from '../firestoreImports'
import geohash from 'ngeohash'
import { User } from './user'
import { Connection } from './connection'
import { Collections, Direction, Place } from './other'
import { Route } from './route'
import moment from 'moment'
import "./extensions"
import Utilities from './utilities'

export enum RideType {
    Single = "s",
    Recurring = "r",
    Exception = "e"
}

//1 - Repeat every day
//2 - Repeat weekly
export class Ride {
    distance?: number

    constructor(
        public id: string,
        public type: RideType,
        public source: Place,
        public destination: Place,
        public cost: string,
        public userId: string,
        public datetime: Date, // Original (departure) datetime
        public timestamp?: Timestamp, // Deprecated?
        public repeatValue?: number,
        public driver?: User,
        public availableSeats?: string,
        public note?: string,
        public recurringRideId?: string,
        public timestampCreated?: Date,
        public canceled?: boolean,
        public noParking?: boolean,
        public connections?: Connection[],
        public company?: string,
        public confirmedConnectionIds?: string[]
    ){}

    newRide?: NewRide

    static fromData(doc: DocumentSnapshot, rideType: RideType): Ride | null {
        if (!doc.data()) {
            console.warn(`Cannot create Ride ${doc.id} from snapshot`)
            return null
        }
        const data = doc.data()!

        const source = new Place(data.source, data.sourceLat, data.sourceLng, data.sourcePlaceId)
        const destination = new Place(data.destination, data.destinationLat, data.destinationLng, data.destinationPlaceId)
        const timestampCreated = (data.timestampCreated as Timestamp | undefined)?.toDate()

        const ride = new Ride(
            doc.id, rideType, source, destination, data.cost, data.userId,
            new Date(data.datetime * 1000), data.timestamp,
            data.repeatValue, undefined, data.availableSeats, data.note, data.recurringRideId, timestampCreated, data.canceled, data.noParking ?? false,
            undefined, data.company, data.confirmedConnectionIds
        )
        return ride
    }

    //Creates new key value object to save new ride on firestore
    asData(): {[k: string]: any} {
        const data: {[k: string]: any} = {
            source:             this.source.name,
            sourceLat:          this.source.lat,
            sourceLng:          this.source.lng,
            sourceGeohash:      geohash.encode(this.source.lat, this.source.lng),
            destination:        this.destination.name,
            destinationLat:     this.destination.lat,
            destinationLng:     this.destination.lng,
            destinationGeohash: geohash.encode(this.destination.lat, this.destination.lng),
            datetime:           this.datetime.getTime() / 1000,
            timestamp:          new Timestamp(this.datetime.getTime() / 1000, 0),
            cost:               this.cost,
            userId:             this.userId
        }
        const seats = parseInt(this.availableSeats ?? "")
        if (seats && !isNaN(seats)) {
            data.availableSeats = seats
        }
        if (this.note) {
            data.note = this.note
        }
        if (this.recurringRideId) {
            data.recurringRideId = this.recurringRideId
        }
        if (this.repeatValue) {
            data.repeatValue = this.repeatValue
        }
        if (this.source.placeId) {
            data.sourcePlaceId = this.source.placeId
        }
        if (this.destination.placeId) {
            data.destinationPlaceId = this.destination.placeId
        }
        if (this.canceled) {
            data.canceled = this.canceled
        }
        if (this.noParking) {
            data.noParking = this.noParking
        }
        if (this.timestampCreated) {
            data.timestampCreated = this.timestampCreated
        }
        if (this.company) {
            data.company = this.company
        }

        return data
    }

    static collectionForType = (type: RideType) => {
        switch (type) {
            case  RideType.Single: return Collections.Rides
            case  RideType.Exception: return Collections.RideExceptions
            case  RideType.Recurring: return Collections.RecurringRides
        }
    }
}

export class RidesPair {
    forward?: NewRide
    backward?: NewRide

    constructor(...rides: (NewRide | undefined)[]) {
        for (const ride of rides) {
            if (ride) {
                this.addRide(ride)
            }
        }
    }

    get routeId() {return this.forward?.routeId ?? this.backward?.routeId}

    addRide = (ride: NewRide, shouldWarn: boolean = true) => {
        if (ride.direction === Direction.Forward) {
            if (this.forward && shouldWarn) {
                console.warn(`This pair for routeId ${ride.routeId} already has a forward ride`)
            }
            this.forward = ride
        } else {
            if (this.backward && shouldWarn) {
                console.warn(`This pair for routeId ${ride.routeId} already has a backward ride`)
            }
            this.backward = ride
        }
    }

    flat = () => {
        const foo = [this.forward, this.backward].filterUndef()
        return foo
    }

    rideForDirection = (direction: Direction) => {
        if (direction === Direction.Forward) {
            return this.forward
        } else {
            return this.backward
        }
    }

    rideForOppositeDirection = (direction: Direction) => {
        if (direction === Direction.Backward) {
            return this.forward
        } else {
            return this.backward
        }
    }
}

export interface NewRideParameters {
    id: string
    userId: string
    routeId: string
    direction?: Direction
    destDatetime: Date
    canceled?: boolean
    noParking?: boolean
    note?: string
    timestampCreated?: Date
    reservationId?: string
    company?: string
}

export class NewRide {
    id: string
    userId: string
    routeId: string
    direction: Direction
    destDatetime: Date
    canceled: boolean
    noParking: boolean
    note?: string
    timestampCreated?: Date
    reservationId?: string
    company?: string

    //Helper
    route?: Route
    driver?: User

    constructor(parameters: NewRideParameters) {
        this.id = parameters.id
        this.userId = parameters.userId
        this.routeId = parameters.routeId
        this.direction = parameters.direction ?? Direction.Forward
        this.destDatetime = parameters.destDatetime
        this.canceled = parameters.canceled ?? false
        this.noParking = parameters.noParking ?? false
        this.note = parameters.note
        this.timestampCreated = parameters.timestampCreated
        this.reservationId = parameters.reservationId
        this.company = parameters.company
    }

    static fromData(doc: DocumentSnapshot): NewRide | null {
        if (!doc.data()) {
            console.warn(`Cannot create NewRide ${doc.id} from snapshot`)
            return null
        }
        const data = doc.data()!
        const userId = data.userId
        const routeId = data.routeId
        const destDatetime = (data.destDatetime as Timestamp | undefined)?.toDate()
        const timestampCreated = (data.timestampCreated as Timestamp | undefined)?.toDate()

        if (!userId || !routeId || !destDatetime) {
            console.warn(`Cannot create NewRide ${doc.id} from snapshot ${data}`)
            return null
        }

        return new NewRide({
            id: doc.id,
            userId: userId,
            routeId: routeId,
            direction: data.direction,
            destDatetime: destDatetime,
            canceled: data.canceled,
            noParking: data.noParking,
            note: data.note,
            timestampCreated: timestampCreated,
            reservationId: data.reservationId,
            company: data.company
        })
    }

    static fromRoute(route: Route, day: Date, timezone: string, generateRideId: () => string): RidesPair {
        const common = {
            userId: route.userId,
            routeId: route.id,
            canceled: route.canceled,
            noParking: route.noParking,
            note: route.note,
            timestampCreated: new Date(),
            company: route.companyCode
        }

        let forwardRide: NewRide | undefined
        if (route.destArrival !== undefined) {
            const datetime = moment.tz(day, timezone).set(Utilities.secondsToTime(route.destArrival))
            forwardRide = new NewRide({...{
                id: generateRideId(),
                direction: Direction.Forward,
                destDatetime: datetime.toDate()
            }, ...common})
        }

        let backwardRide: NewRide | undefined
        if (route.destDeparture !== undefined) {
            const datetime = moment.tz(day, timezone).set(Utilities.secondsToTime(route.destDeparture))
            backwardRide = new NewRide({...{
                id: generateRideId(),
                direction: Direction.Backward,
                destDatetime: datetime.toDate()
            }, ...common})
        }
        for (const ride of [forwardRide, backwardRide]) {
            if (ride) {
                ride.route = route
                ride.driver = route.driver
            }
        }
        return new RidesPair(forwardRide, backwardRide)
    }

    asData(): {[k: string]: any} {
        const data: {[k: string]: any} = {
            userId:             this.userId,
            routeId:            this.routeId,
            direction:          this.direction,
            destDatetime:       this.destDatetime,
            canceled:           this.canceled,
            noParking:          this.noParking
        }
        if (this.note) {
            data.note = this.note
        }
        if (this.timestampCreated) {
            data.timestampCreated = this.timestampCreated
        }
        if (this.reservationId) {
            data.reservationId = this.reservationId
        }
        if (this.company) {
            data.company = this.company
        }
        return data
    }

    maybeAsLegacyRide = (): Ride | null => {
        if (!this.route) {
            console.warn("Cannon create legacy ride from new ride (no route)")
            return null
        }
        return this.asLegacyRide(this.route)
    }

    asLegacyRide = (route: Route): Ride => {
        let rideDepartureDatetime = this.destDatetime
        let origin = route.origin
        let destination = route.destination
        if (this.direction !== Direction.Backward) {
            rideDepartureDatetime = moment(this.destDatetime).subtract(route.duration, 's').toDate()
        } else {
            origin = route.destination
            destination = route.origin
        }

        const ride = new Ride(
            this.id, RideType.Single, origin, destination, "", this.userId,
            rideDepartureDatetime, undefined,
            undefined, route.driver, "4", this.note, undefined, new Date(), this.canceled, this.noParking,
            undefined, route.companyCode, undefined)
        ride.newRide = this
        return ride
    }
}