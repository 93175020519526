import { styled } from "@mui/material";
import { DatePicker, DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { FC } from "react";

const MuiStyledDateTimePicker = styled(DateTimePicker)(() => ({
    "& .MuiInputBase-input": {
        "border": "none",
        "padding": "12px 10px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        "border": "1px solid #33058D",
    }
}))

interface IStyledDateTimePickerProps {
    value: string | moment.Moment
    onChange: (value: string | moment.Moment) => void
    timezone?: string
    className?: string
}

const StyledDateTimePicker: FC<IStyledDateTimePickerProps> = ({ value, onChange, timezone, className }) => {
    const ifMoment = (onChange: (date: string | moment.Moment) => void) => {
        return (value: unknown, _: PickerChangeHandlerContext<DateTimeValidationError>) => {
            if (typeof value === "string" || moment.isMoment(value)) {
                onChange(value)
            }
        }
    }

    return <MuiStyledDateTimePicker className={className} value={value} timezone={timezone} onChange={ifMoment(onChange)} />
}

export default StyledDateTimePicker;

const MuiStyledTimePicker = styled(TimePicker)(() => ({
    "& .MuiInputBase-input": {
        "border": "none",
        "padding": "12px 10px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        "border": "1px solid #33058D",
    }
}))

interface IStyledTimePickerProps {
    value: string | moment.Moment
    onChange: (value: string | moment.Moment) => void
    timezone?: string
    className?: string
}

const StyledTimePicker: FC<IStyledTimePickerProps> = ({ value, onChange, timezone, className }) => {
    const ifMoment = (onChange: (date: string | moment.Moment) => void) => {
        return (value: unknown, _: PickerChangeHandlerContext<DateTimeValidationError>) => {
            if (typeof value === "string" || moment.isMoment(value)) {
                onChange(value)
            }
        }
    }

    return <MuiStyledTimePicker className={className} value={value} timezone={timezone} onChange={ifMoment(onChange)} />
}

export { StyledTimePicker }

const MuiStyledDatePicker = styled(DatePicker)(() => ({
    "& .MuiInputBase-input": {
        "border": "none",
        "padding": "12px 10px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        "border": "1px solid #33058D",
    }
}))

interface IStyledDatePickerProps {
    value: string | moment.Moment
    onChange: (value: string | moment.Moment) => void
    timezone?: string
    className?: string
}

const StyledDatePicker: FC<IStyledDatePickerProps> = ({ value, onChange, timezone, className }) => {
    const ifMoment = (onChange: (date: string | moment.Moment) => void) => {
        return (value: unknown, _: PickerChangeHandlerContext<DateTimeValidationError>) => {
            if (typeof value === "string" || moment.isMoment(value)) {
                onChange(value)
            }
        }
    }

    return <MuiStyledDatePicker className={className} value={value} timezone={timezone} onChange={ifMoment(onChange)} />
}

export { StyledDatePicker }