import * as React from 'react';

import { styled } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import moment from 'moment';
import { FormattedDate, useIntl } from 'react-intl';

const MuiStyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    width: '100%',
    "& .MuiButtonBase-root": {
        "border": "1px solid #33058D",
        "width": "100px"
    }
}))
const MuiStyledToggleButton = styled(ToggleButton)(() => ({
    "& .MuiButtonBase-root": {
        "padding": "0",
    },
}))

export enum Weekday {
    Mon = 1, Tue = 2, Wed = 3, Thu = 4, Fri = 5, Sat = 6, Sun = 7
}

interface IScheduleSelectProps {
    showDate: boolean
    onChange?: (selection: Weekday[]) => void
    initialSelection?: Weekday[]
}

const ScheduleSelect = (props: IScheduleSelectProps) => {
    const [selectedOffset, setSelectedOffset] = React.useState<Weekday[]>(props.initialSelection ?? [])
    const rotation = props.showDate ? moment().isoWeekday()-1 : 0
    const intl = useIntl()

    moment.locale(intl.locale)

    const handleDaySelect = (_: React.MouseEvent<HTMLElement>, newValues: Weekday[]) => {
        if (props.onChange) {
            setSelectedOffset(newValues.map(day => (day + rotation)%7))
            props.onChange(newValues)
        }
    }

    return (
        <MuiStyledToggleButtonGroup
            color="primary"
            value={selectedOffset.map(offset => (offset - rotation +7)%7)}
            onChange={handleDaySelect}
            className='w-auto'
        >
        {[...Array(7).keys()].map(dayOffset =>
            <MuiStyledToggleButton className='w-auto' disabled={props.onChange === undefined} value={dayOffset} key={dayOffset} aria-label="bold">
                {moment.weekdaysShort()[(dayOffset + rotation + 1) %7]}
                {props.showDate ? <><br />{<FormattedDate value={moment().add(dayOffset, 'd').toDate()} month="numeric" day="numeric" />}</>: <></>}
            </MuiStyledToggleButton>
        )}
        </MuiStyledToggleButtonGroup>
    );
}

export {ScheduleSelect}