import moment from 'moment-timezone'
import { Reservation } from './reservation.js'
import { Place } from './other.js'
moment.locale('en')

export default class Utilities {

    static reservationTimeToText = (reservation: Reservation, timezone: string, locale: string = 'en') => {
        const date = this.dateToText(reservation.startTime, timezone, locale)
        const timeText = this.reservationTimeToTextShort(reservation, timezone, locale)
        if (timeText) {
            return date + ' ' + timeText
        } else {
            return date
        }

    }

    static dateToText = (date: Date, timezone: string, locale: string = 'en') => {
        const calendarOptions = locale === 'cs' ? this.calendarOptionsCs : this.calendarOptionsEn
        return moment.tz(date, timezone).locale(locale).calendar(calendarOptions)
    }

    static reservationTimeToTextShort = (reservation: Reservation, timezone: string | undefined, locale: string = 'en') => {
        if (timezone === undefined) {
            return ''
        }
        const localizedTime = locale === 'cs' ? this.localizedTimeCs : this.localizedTimeEn

        const startTime = moment.tz(reservation.startTime, timezone)
        const endTime = moment.tz(reservation.endTime, timezone)

        if (startTime.isSame(startTime.clone().startOf('d')) &&
              endTime.isSame(startTime.clone().set({hour:12,minute:0,second:0,millisecond:0}), 'minute'))
        {
            return localizedTime.morning
        } else if (startTime.isSame(endTime.clone().set({hour:12,minute:0,second:0,millisecond:0}), 'seconds') &&
                     endTime.isSame(endTime.clone().endOf('d'), 'minute'))
        {
            return localizedTime.afternoon
        } else if (startTime.isSame(endTime.clone().startOf('d'), 'seconds') &&
                     endTime.isSame(endTime.clone().endOf('d'), 'minute'))
        {
            return ''
        }
        return      localizedTime.from + ' ' + startTime.locale(locale).format('LT') +
                ' ' + localizedTime.to + ' ' + endTime.locale(locale).format('LT')
    }

    static localizedTimeEn = {
        morning: 'morning',
        afternoon: 'afternoon',
        from: 'from',
        to: 'to'
    }
    static localizedTimeCs = {
        morning: 'dopoledne',
        afternoon: 'odpoledne',
        from: 'od',
        to: 'do'
    }
    static calendarOptionsEn: moment.CalendarSpec = {
        sameDay: '[today]',
        nextDay: '[tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[yesterday]',
        lastWeek: '[last] dddd',
        sameElse: 'L'
    }
    static calendarOptionsCs: moment.CalendarSpec = {
        sameDay: '[dnes]',
        nextDay: '[zítra]',
        nextWeek: 'D. M.',
        lastDay: '[včera]',
        lastWeek: 'D. M.',
        sameElse: 'D. M.'
    }
    static calendarOptionsOn: moment.CalendarSpec = {
        sameDay: '[today]',
        nextDay: '[tomorrow]',
        nextWeek: '[on] dddd',
        lastDay: '[yesterday]',
        lastWeek: '[last] dddd',
        sameElse: '[on] L'
    }

    static parseTime = (t: string, date: Date, timezone: string) => {
        const time = t.match(/\d+/g)
        const hours = parseInt(time![0] ?? 0 )
        const minutes = parseInt(time![1] ?? 0 )
        return moment.tz(date, timezone).set({hour: hours, minute: minutes, second:0,millisecond:0})
    }

    // Distance of two places in km
    static calculateDistance = (placeA: Place, placeB: Place) => {
        // Approximation for out geographical region
        const latDelta = (placeA.lat - placeB.lat) * 111 //km delta
        const lngDelta = (placeA.lng - placeB.lng) * 71
        return Math.sqrt(latDelta**2 + lngDelta**2)
    }

    static secondsToTime = (timestamp: number) => {
        const hours = Math.floor(timestamp / 3600)
        const minutes = Math.floor(timestamp / 60) % 60
        const seconds = timestamp % 60
        return {h: hours, m: minutes, s: seconds, ms: 0}
    }
}