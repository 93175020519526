import { initializeApp } from 'firebase/app';
import {
    Auth, getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail, applyActionCode,
    verifyPasswordResetCode, confirmPasswordReset, NextOrObserver, User as FIRUser, onAuthStateChanged, OAuthProvider, signInWithRedirect, getRedirectResult,
} from 'firebase/auth';
import { initializeFirestore, Firestore, Timestamp, setDoc, doc, getDoc, getDocs, query, collection, deleteDoc, addDoc, where, QuerySnapshot, updateDoc, orderBy, serverTimestamp, DocumentSnapshot, CollectionReference, QueryConstraint, limit } from 'firebase/firestore';
import geohash from 'ngeohash';
import axios from 'axios';
import moment from 'moment-timezone'

import { RideType, ApiError, ApiErrorCode, Ride, User, Connection, Car, Location, Company, Collections, Route } from '.';

import {firebaseConfig} from 'constants/config.js'
import { Match } from './models/match';
import { NewRide, RidesPair } from './models/ride';
const clone = require('clone');

class Firebase {
    private firestore: Firestore
    private auth: Auth

    constructor() {
        const app = initializeApp(firebaseConfig)

        this.firestore = initializeFirestore(app, {experimentalAutoDetectLongPolling: true})
        this.auth = getAuth(app)
    }

    // *** Auth API ***

    createUserWithEmailAndPassword = (email: string, password: string) => {
        return createUserWithEmailAndPassword(this.auth, email, password).then(authUser => {
            sendEmailVerification(authUser.user)
            return authUser
        })
    };

    deleteAuthUser = (authUser: FIRUser) => {
        authUser.delete()
    };

    sendVerificationEmail = () => {
        if (this.auth.currentUser) {
            sendEmailVerification(this.auth.currentUser)
        }
    };

    signInWithEmailAndPassword = (email: string, password: string) => signInWithEmailAndPassword(this.auth, email, password)

    singInWithSSO = (providerId: string, email: string) => {
        const provider = new OAuthProvider(providerId)
        provider.setCustomParameters({
            login_hint: email
        })
        //TODO probably remove this (okta specifix)
        //provider.addScope('okta.users.read.self')
        provider.addScope('email')
        provider.addScope('profile')
        provider.addScope('address')
        return signInWithRedirect(this.auth, provider)
    }

    getRedirectResult = () => {
        return getRedirectResult(this.auth)
    }

    signOut = () => this.auth.signOut();

    passwordReset = (email: string) => sendPasswordResetEmail(this.auth, email);

    verifyEmail = (actionCode: string) => applyActionCode(this.auth, actionCode);

    verifyPasswordResetCode = (actionCode: string) => verifyPasswordResetCode(this.auth, actionCode);

    confirmPasswordReset = (actionCode: string, newPassword: string) => confirmPasswordReset(this.auth, actionCode, newPassword);

    onAuthStateChanged = (nextOrObserver :  NextOrObserver<FIRUser>) => onAuthStateChanged(this.auth, nextOrObserver)
    //onAuthStateChanged = (nextOrObserver :  firebase.Observer < any > | ( ( a :  firebase.User | null ) => any )) => this.auth.onAuthStateChanged(nextOrObserver)

    // *** User data API ***
    fetchUserData = (userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return getDoc(ref).then(doc => {
            if (doc.exists()) {
                const user = User.fromData(doc)
                return Promise.resolve(user)
            } else {
                return Promise.reject(new ApiError(`Getting user ${userId} failed, empty data`))
            }
        })
    };

    fetchUserPrivateData = async (user: User) => {
        const ref = doc(this.firestore, Collections.Users, user.uid, Collections.UserPrivate, Collections.UserContactData)
        try {
            const snap = await getDoc(ref)
            if (snap.exists()) {
                const data = snap.data()!
                user.email = data.email
                user.phone = data.phone
                user.surname = data.surname
            }
        } catch (error) {
            console.warn(`Fetching private data for user ${user.uid} failed: `, error)
        } finally {
            return user
        }
    }

    fetchUserDataWithCars = async (userId: string, includePrivate: boolean) => {
        const [userResult, carResult] = await Promise.allSettled([this.fetchUserData(userId), this.fetchCarsForUserId(userId)])
        if (userResult.status === "rejected") {
            console.log("Fetching user with cars failed: ", userResult.reason);
            return Promise.resolve(null)
        } else if (carResult.status === "rejected") {
            console.log("Fetching user with cars failed: ", carResult.reason);
            return Promise.resolve(null)
        }
        const user = userResult.value
        if (user && user.locationIds) {
            if (includePrivate) {
                try {
                    await this.fetchUserPrivateData(user)
                } catch (error) {
                    console.log(`Error fetching private data of user ${user.uid}: `, error)
                }
            }
            user.cars = carResult.value;
            const locations = await Promise.all(user.locationIds.map(this.fetchLocation))
            user.locations = locations.filter(l => l).map(l => l!)
            return Promise.resolve(user);
        }
        return Promise.resolve(null);
    }

    setAuthLocale = (locale: string) => {
        this.auth.languageCode = locale
    }

    fetchUsers = async (userIds: string[], includePrivate: boolean = false) => {
        //Create array of promises for userIds
        const userIdsSet = new Set(userIds)
        const userPromises = Array.from(userIdsSet).filter(userId => userId).map(userId => {
            const ref = doc(this.firestore, Collections.Users, userId)
            return getDoc(ref)
        })
        const docs = await Promise.all(userPromises)
        const users = docs.map(User.fromData).filter(u => u).map(u => u!)

        if (includePrivate) {
            await Promise.all(users.map(this.fetchUserPrivateData))
        }

        let usersMap: Record<string, User> = {}
        users.forEach((user) => {
            usersMap[user.uid] = user
        })
        return Promise.resolve(usersMap)
    }

    fetchAllUsers = async (companyCode: string) => {
        const q = query(collection(this.firestore, Collections.Users), where("company", "==", companyCode))
        const querySnapshot = await getDocs(q)

        const users = querySnapshot.docs.map(User.fromData).filter(u => u).map(u => u!)

        await Promise.all(users.map(this.fetchUserPrivateData))

        return users.filter(u => !u.deleted)
    }

    fetchCompleteUsers = async () => {
        const q = query(collection(this.firestore, Collections.Users))
        const querySnapshot = await getDocs(q)
        const users = querySnapshot.docs.map(User.fromData).filterUndef()

        let i = 0
        while (i+100 < users.length) {
            const usersSlice = users.slice(i, i+100)
            await Promise.allSettled(usersSlice.map(this.fetchUserPrivateData))
            i+=100
        }
        return new Promise<User[]>(resolve => resolve(users))
    };

    setUser = async (user: User) => {
        const ref = doc(this.firestore, Collections.Users, user.uid)
        await setDoc(ref, {
            timestampUpdated: serverTimestamp(),
            name: user.name,
            surname: user.surname.substring(0, 1),
            email: "",
            company: user.company ?? "",
            priority: user.priority ?? 0,
            locations: user.locationIds ?? null,
            visitorCompany: user.visitorCompany ?? null,
            host: user.host ?? null
        }, { merge: true }).catch(error => {
            console.log("Error setting user: ", error)
        })
        const privateDataRef = doc(this.firestore, Collections.Users, user.uid, Collections.UserPrivate, Collections.UserContactData)
        await setDoc(privateDataRef, {
            timestampUpdated: serverTimestamp(),
            surname: user.surname,
            phone: user.phone ?? "",
            email: user.email
        }, { merge: true }).catch(error => {
            console.log("Error setting user private data: ", error)
        })
    }

    setUserLocale= (userId: string, locale: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return setDoc(ref, {
            locale: locale
        }, {merge: true})
    };

    getUsersLastActiveTime = (user: User) => {
        return user.lastActiveCarpoolWeb
    }

    setUsersLastActiveTime = (uid: string) => {
        const ref = doc(this.firestore, Collections.Users, uid)
        return setDoc(ref, {
            lastActiveCarpoolWeb: serverTimestamp()
        }, { merge: true }).catch(error => {
            console.log("Error setting user: ", error)
        })
    };

    deleteUser = (userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return setDoc(ref, {
                deleted: true,
            }, { merge: true })
    };

    // *** Admin ***
    fetchAdminPrivileges = async (userId: string, user: User | undefined = undefined): Promise<{locationsIds: string[], companies: string[]}> => {
        const ref = doc(this.firestore, Collections.Admins, userId)
        const querySnapshot = await getDoc(ref)
        if (!querySnapshot.exists()) {
            return {locationsIds: [], companies: []}
        }
        const data = querySnapshot.data()
        if (user) {
            user.adminLocations = data?.locations ?? []
            user.adminCompanies = data?.companies ?? []
        }
        return {locationsIds: data?.locations ?? [], companies: data?.companies ?? []}
    }

    setAdminLocations = (user: User, companiesIds: string[], locationsIds: string[]) => {
        const ref = doc(this.firestore, Collections.Admins, user.uid)
        if (locationsIds.length === 0) {
            companiesIds = []
        } else if (companiesIds.length === 0) {
            companiesIds = [user.company]
        }
        return setDoc(ref, {
            companies: companiesIds,
            locations: locationsIds,
            email: user.email
        }, { merge: true })
    };

    // *** Locations ***
    fetchLocation = async (locationCode: string) => {
        const ref = doc(this.firestore, Collections.Parking, locationCode)
        const documentSnapshot = await getDoc(ref)
        if (!documentSnapshot.exists()) {
            return null
        }
        return Location.fromData(documentSnapshot)
    }

    // *** Companies ***
    fetchCompany = async (companyCode: string) => {
        const ref = doc(this.firestore, Collections.Companies, companyCode)
        const documentSnapshot = await getDoc(ref)
        return Company.fromData(documentSnapshot)
    }


    // *** Rides data API ***
    fetchSingleRide = (rideId: string): Promise<Ride> => {
        const ref = doc(this.firestore, Collections.Rides, rideId)
        return getDoc(ref).then(doc => {
            if (!doc.exists) {
                return Promise.reject(new ApiError(`No ride exists for id ${rideId}.`, ApiErrorCode.NoRide))
            }
            let ride = this.createRideFromSnapshot(doc, RideType.Single)
            if (ride) {
                return this.fillRidesDrivers([ride]).then(rides => {
                    return new Promise(resolve => resolve(rides[0]))
                })
            }
            return Promise.reject(`Getting single ride ${rideId} failed`)
        })
    };

    fetchExceptionRide = (rideId: string): Promise<Ride> => {
        const ref = doc(this.firestore, Collections.RideExceptions, rideId)
        return getDoc(ref).then((doc: any) => {
            if (!doc.exists) {
                return new Promise((_, reject) => reject(new ApiError(`No ride exists for id ${rideId}.`, ApiErrorCode.NoRide)))
            }
            let ride = this.createRideFromSnapshot(doc, RideType.Exception)
            if (ride) {
                return this.fillRidesDrivers([ride]).then(rides => {
                    return new Promise(resolve => resolve(rides[0]))
                })
            }
            return Promise.reject(`Getting exception ride ${rideId} failed`)
        })
    };

    fetchSingleRides = (sinceDate?: Date, userId?: string, visibleCompanies?: string[], fillDrivers: boolean = true, includePrivate: boolean = false): Promise<Ride[]> => {
        //TODO types
        let constraints: [QueryConstraint] = [orderBy("datetime")]
        if (sinceDate) {
            sinceDate.getTime()
            const timestamp = Math.floor(sinceDate.getTime() / 1000)
            constraints.push(where("datetime", ">", timestamp))
        }
        if (userId) {
            constraints.push(where("userId", "==", userId))
        }
        if (visibleCompanies && visibleCompanies.length > 0) {
            constraints.push(where("company", "in", visibleCompanies))
        }
        const q = query(collection(this.firestore, Collections.Rides),...constraints)

        return getDocs(q).then((querySnapshot: any) => {
            let rides: Ride[] = []
            querySnapshot.forEach((doc: any) => {
                if ((doc.data().repeatValue ?? 0) > 0) { //Skip recurring rides
                    return
                }
                let ride = this.createRideFromSnapshot(doc, RideType.Single)
                if (ride) {
                    rides.push(ride)
                }
            })
            if (fillDrivers) {
                return this.fillRidesDrivers(rides, includePrivate)
            } else {
                return Promise.resolve(rides)
            }
        })
    };

    fetchRecurringRides = (userId?: string, visibleCompanies?: string[], fillDrivers: boolean = true, includePrivate: boolean = false): Promise<Ride[]> => {
        //TODO types
        let constraints: [QueryConstraint] = [orderBy("datetime")]
        if (userId) {
            constraints.push(where("userId", "==", userId))
        }
        if (visibleCompanies && visibleCompanies.length > 0) {
            constraints.push(where("company", "in", visibleCompanies))
        }
        const q = query(collection(this.firestore, Collections.RecurringRides),...constraints)

        return getDocs(q).then((querySnapshot: any) => {
            let rides: Ride[] = []
            querySnapshot.forEach((doc: any) => {
                let ride = this.createRideFromSnapshot(doc, RideType.Recurring)
                if (ride) {
                    rides.push(ride)
                }
            })
            if (fillDrivers) {
                return this.fillRidesDrivers(rides, includePrivate)
            } else {
                return Promise.resolve(rides)
            }
        })
    };

    fetchExceptionRides = (sinceDate?: Date, userId?: string, visibleCompanies?: string[], fillDrivers: boolean = true, includePrivate: boolean = false): Promise<Ride[]> => {
        //TODO types
        let constraints: [QueryConstraint] = [orderBy("datetime")]
        if (sinceDate) {
            sinceDate.getTime()
            const timestamp = Math.floor(sinceDate.getTime() / 1000)
            constraints.push(where("datetime", ">", timestamp))
        }
        if (userId) {
            constraints.push(where("userId", "==", userId))
        }
        if (visibleCompanies && visibleCompanies.length > 0) {
            constraints.push(where("company", "in", visibleCompanies))
        }
        const q = query(collection(this.firestore, Collections.RideExceptions),...constraints)

        return getDocs(q).then((querySnapshot: any) => {
            let rides: Ride[] = []
            querySnapshot.forEach((doc: any) => {
                let ride = this.createRideFromSnapshot(doc, RideType.Exception)
                if (ride) {
                    rides.push(ride)
                }
            })
            if (fillDrivers) {
                return this.fillRidesDrivers(rides, includePrivate)
            } else {
                return Promise.resolve(rides)
            }
        })
    };

    createRideFromSnapshot = (doc: DocumentSnapshot, rideType: RideType): Ride | null => {
        return Ride.fromData(doc, rideType)
    }

    addDaysToRide = (ride: Ride, numDays: number) => {
        let date = new Date(ride.datetime)
        date.setDate(date.getDate() + numDays);
        ride.datetime = date
    };

    processRecurringRides = (rides: Ride[], exceptionRides: Ride[], sinceDate?: Date, repetitions: number = 2, toDate?: Date) => {
        const since = moment(sinceDate)
        const uptoDate = moment(toDate)
        const recurringRides: Ride[] = []
        rides.forEach(ride => {
            if (ride.repeatValue === 1) {  //Repeat every day
                const firstRideDatetime = moment(ride.datetime)
                while (firstRideDatetime < since) {
                    firstRideDatetime.add(1, 'd')
                }
                for (let i = 0; i < repetitions*7; i++) {
                    const rideDatetime = moment(firstRideDatetime).tz('Europe/Prague').add(i, 'd')
                    if (toDate && rideDatetime.isAfter(uptoDate)) {
                        break
                    }
                    if (rideDatetime.isoWeekday() !== 6 && rideDatetime.isoWeekday() !== 7) {
                        const newRide = clone(ride, false, 1)
                        newRide.datetime = rideDatetime.toDate()
                        recurringRides.push(newRide)
                    }
                }
            } else if (ride.repeatValue === 2) { //Repeat every week
                const firstRideDatetime = moment(ride.datetime)
                while (firstRideDatetime < since) {
                    firstRideDatetime.add(7, 'd')
                }
                for (let i = 0; i < repetitions; i++) {
                    const newRideDatetime = moment(firstRideDatetime).tz('Europe/Prague').add(i*7, 'd')
                    if (toDate && newRideDatetime.isAfter(uptoDate)) {
                        break
                    }
                    const newRide = clone(ride, false, 1)
                    //const newRide = {...ride} as Ride //does not create Ride object
                    newRide.datetime = newRideDatetime.toDate()
                    recurringRides.push(newRide)
                }
            }
        })
        //Filter out exception rides from recurring templated rides
        const filteredRecurringRides = recurringRides.filter(ride => {
            return exceptionRides.filter(exRide => {
                const msDifference = ride.datetime.getTime() - exRide.datetime.getTime() //allow 1 hour difference for daylight savings
                return ride.id === exRide.recurringRideId && (Math.abs(msDifference) <= 1000*60*60)
            }).length === 0
        })
        return filteredRecurringRides
    };

    expandRoute = (location: Location, route: Route, startDate: Date, endDate: Date) => {
        let result: RidesPair[] = []
        const daysCount = moment(endDate).diff(startDate, 'days')

        const firstDayMoment = moment.tz(startDate, location.timezone)
        for (let i=0; i<=daysCount; i++) {
            const day = moment(firstDayMoment).add(i, "days")
            // Check schedule
            if (!route.schedule.includes(day.isoWeekday())) {
                continue
            }
            const ridesPair = NewRide.fromRoute(route, day.toDate(), location.timezone, this.generateRideId)
            result.push(ridesPair)
        }
        return result
    }

    generateRideId = () => {
        return doc(collection(this.firestore, Collections.Rides)).id
    }

    //Fills drivers from Firestore for each ride and returns promise with updated rides
    fillRidesDrivers = (rides: Ride[], includePrivate: boolean = false) => {
        //Create array of promises for ride users
        const userIds = rides.map(ride => ride.userId)
        return this.fetchUsers(userIds, includePrivate).then(usersMap => {
            rides.forEach((ride) => {
                ride.driver = usersMap[ride.userId]
            })
            return Promise.resolve(rides)
        })
    };

    fetchAllRides = (sinceDate?: Date, userId?: string, analytics = false, toDate?: Date, visibleCompanies?: string[], fillDrivers: boolean = true, includePrivate: boolean = false): Promise<Ride[]> => {
    //TODO add to date
        return Promise.all([
            this.fetchSingleRides(sinceDate, userId, visibleCompanies, fillDrivers, includePrivate),
            this.fetchRecurringRides(userId, visibleCompanies, fillDrivers, includePrivate),
            this.fetchExceptionRides(sinceDate, userId, visibleCompanies, fillDrivers, includePrivate)
        ]).then(([singleRides, recurringRides, exceptionRides]) => {
            const processedRecurringRides = analytics ? this.processRecurringRides(recurringRides, exceptionRides, sinceDate, 150, toDate) : this.processRecurringRides(recurringRides, exceptionRides)
            let allRides = [...singleRides, ...processedRecurringRides, ...exceptionRides]
            if (toDate) {
                allRides = allRides.filter(r => r.datetime.getTime() < toDate.getTime())
            }
            return Promise.resolve(allRides)
        })
    };

    // Returns a ride according to id conmposed of char ["s", "r" or "e"], undeline [_] and ride id
    fetchRideWithComposedId = (composedId: string): Promise<Ride> => {
        const rideType = composedId.charAt(0)
        let rideTypeEnum: RideType = RideType.Single
        let ridesCollection = Collections.Rides
        switch (rideType) {
            case RideType.Recurring:
                ridesCollection = Collections.RecurringRides
                rideTypeEnum = RideType.Recurring
                break;
            case RideType.Exception:
                ridesCollection = Collections.RideExceptions
                rideTypeEnum = RideType.Exception
                break;
        }

        const rideId = composedId.substring(2)
        const ref = doc(this.firestore, ridesCollection, rideId)
        return getDoc(ref).then((doc) => {
            let ride: Ride = doc.data() as Ride
            if (ride) {
                let ride = this.createRideFromSnapshot(doc, rideTypeEnum)
                if (ride) {
                    return this.fillRidesDrivers([ride]).then(rides => {
                        return new Promise(resolve => resolve(rides[0]))
                    })
                }
            }
            return Promise.reject()
        })
    };

    // Checks if exception ride exists and returns such, if not returns recurringRide
    checkForExceptionRide = (recurringRide: Ride): Promise<Ride> => {
        const q = query(collection(this.firestore, Collections.RideExceptions),
                where("recurringRideId", "==", recurringRide.id),
                where("datetime", "==", recurringRide.datetime.getTime()/1000)
            )
        return getDocs(q).then((querySnapshot): Promise<any> => {
            if (!querySnapshot.empty) {  //Ride exception already exists
                const ride = this.createRideFromSnapshot(querySnapshot.docs[0], RideType.Exception)
                if (ride) {
                    ride.driver = recurringRide.driver
                    return Promise.resolve(ride)
                }
            }
            return Promise.resolve(recurringRide)
        })
    }

    // Calculate the upper and lower boundary geohashes for a given latitude, longitude, and distance in km
    //getGeohashRange = (latitude: number, longitude: number, distance: number) => {
    getGeohashRange = (latitude: number, longitude: number, distance: number) => {
        const lat = 0.009009009009; // degrees latitude per km
        const lon = 0.01176470588; // cca degrees longitude per km

        const lowerLat = latitude - lat * distance;
        const lowerLon = longitude - lon * distance;

        const upperLat = latitude + lat * distance;
        const upperLon = longitude + lon * distance;

        const lower = geohash.encode(lowerLat, lowerLon);
        const upper = geohash.encode(upperLat, upperLon);

        return {
            lower,
            upper
        };
    };

    fetchRidesInRadius = (center: any, distance: number) => {
        //const {latitude, longitude} = center
        //TDO
        const range = this.getGeohashRange(center.lat, center.lng, distance);
        //console.log(latitude);
        //console.log("Searchin geohash range", range);

        const q = query(collection(this.firestore, Collections.Rides),
                where("sourceGeohash", ">=", range.lower),
                where("sourceGeohash", "<=", range.upper)
            )
        return getDocs(q).then((querySnapshot) => {
            let rides: Ride[] = []
            querySnapshot.forEach(doc => {
                const ride = this.createRideFromSnapshot(doc, RideType.Exception)
                if (ride) {
                    rides.push(ride)
                }
            })
            return this.fillRidesDrivers(rides)
        })
    };

    fetchRides = (startDate: Date, endDate?: Date) => {
        return this.fetchRidesForRoute(undefined, startDate, endDate)
    }

    fetchRidesForRoute = async (route: Route | undefined, startDate: Date, endDate?: Date) => {
        if (!endDate) {
            endDate = startDate
        }

        const constraints: QueryConstraint[] = [
            where("destDatetime", ">=", startDate),
            where("destDatetime", "<=", endDate)
        ]

        if (route) {
            constraints.push(
                where("routeId", "==", route.id),
            )
        }

        const q = query(collection(this.firestore, Collections.Rides),
                ...constraints
            )

        const querySnapshot = await getDocs(q)
        const rides = querySnapshot.docs.map(doc =>  NewRide.fromData(doc)).filter(r => r).map(r => r!)
        rides.forEach(r => r.route = route)
        rides.forEach(r => r.driver = route?.driver)
        return rides
    }

    // *** Join ride API ***
    joinRide = (ride: Ride, authUser: any) => {
        if (ride.type === RideType.Recurring) {
            const rideInstance: Ride = clone(ride)
            rideInstance.timestampCreated = new Date()
            rideInstance.recurringRideId = ride.id

            const q = query(collection(this.firestore, Collections.RideExceptions),
                where("recurringRideId", "==", ride.id),
                where("datetime", "==", ride.datetime.getTime()/1000)
            )
            return getDocs(q).then((querySnapshot): Promise<any> => {
                if (!querySnapshot.empty) {  //Ride exception already exists
                    return Promise.resolve(querySnapshot.docs[0]);
                } else { //Create ride exception
                    const col = collection(this.firestore, Collections.RideExceptions)
                    return addDoc(col, rideInstance.asData())
                }
            }).then(docRef => {
                rideInstance.id = docRef.id
                return this.addConnection(rideInstance, authUser.uid, true)
            })
        } else if (ride.type === RideType.Exception) {
            return this.addConnection(ride, authUser.uid, true)
        } else {
            return this.addConnection(ride, authUser.uid, false)
        }
    };

    joinRideForWeek = (ride: Ride, authUser: any) => {
        if (ride.type === RideType.Recurring) {
            ride.recurringRideId = ride.id
        }

        let rides = [ride]
        for (let i = 1; i < 6 - ride.datetime.getDay(); i++) {
            let newRide = clone(ride)
            newRide.timestampCreated = serverTimestamp()
            this.addDaysToRide(newRide, i)
            rides.push(newRide)
        }

        let promises: Promise<any>[] = []
        rides.forEach(rideInstance => {
            const q = query(collection(this.firestore, Collections.RideExceptions),
                where("recurringRideId", "==", ride.recurringRideId),
                where("datetime", "==", rideInstance.datetime.getTime()/1000)
            )
            const promise = getDocs(q).then((querySnapshot): Promise<any> => {
                if (!querySnapshot.empty) {  //Ride exception already exists
                    return Promise.resolve(querySnapshot.docs[0]);
                } else { //Create ride exception
                    const col = collection(this.firestore, Collections.RideExceptions)
                    return addDoc(col, rideInstance.asData())
                }
            }).then(docRef => {
                rideInstance.id = docRef.id
                return this.addConnection(rideInstance, authUser.uid, true)
            })
            promises.push(promise)
        })
        return Promise.all(promises).catch(e => {
            console.warn("Error joining ride for week: ", e)
        })
    };

    cancelRide = (ride: Ride) => {
        let ridesCollection = ""
        switch (ride.type) {
            case RideType.Single: ridesCollection = Collections.Rides
                break;
            case RideType.Recurring: ridesCollection = Collections.RecurringRides
                break;
            case RideType.Exception: ridesCollection = Collections.RideExceptions
                break;
            default: ridesCollection = Collections.Rides
        }
        const ref = doc(this.firestore, ridesCollection, ride.id)
        return updateDoc(ref, {canceled: true})
    };
    cancelRecurringRideException = (recurringRide: Ride) => {
        const rideInstance: Ride = clone(recurringRide)
        rideInstance.recurringRideId = recurringRide.id
        rideInstance.timestampCreated = new Date()
        rideInstance.canceled = true
        const q = query(collection(this.firestore, Collections.RideExceptions),
            where("recurringRideId", "==", recurringRide.id),
            where("datetime", "==", recurringRide.datetime.getTime()/1000)
        )
        return getDocs(q).then((querySnapshot): Promise<any> => {
            if (!querySnapshot.empty) {  //Ride exception already exists
                const ref = doc(this.firestore, Collections.RideExceptions, querySnapshot.docs[0].id)
                return updateDoc(ref, {canceled: true})
            } else { //Create ride exception
                const ref = collection(this.firestore, Collections.RideExceptions)
                return addDoc(ref, rideInstance.asData())
            }
        })
    }
    cancelRecurringRide = (recurringRideId: string) => {
        const ref = doc(this.firestore, Collections.RecurringRides, recurringRideId)
        return updateDoc(ref, {
            canceled: true
        })
    }

    cancelConnection = (connectionId: string) => {
        const ref = doc(this.firestore, Collections.Connections, connectionId)
        return updateDoc(ref, {
            canceled: true
        })
    };

    addConnection = (ride: Ride, passengerId: string, recurring: boolean) => {
        let ref = collection(this.firestore, Collections.Connections)
        return addDoc(ref, {
            rideId:             ride.id,
            passengerId:        passengerId,
            driverId:           ride.userId,
            timestampCreated:   serverTimestamp(),
            rideDatetime:       ride.datetime.getTime() / 1000,
            rideTimestamp:      new Timestamp(ride.datetime.getTime() / 1000, 0),
            isRecurring:        recurring,
            //TODO add destDatetime
        })
    };


    // *** Offer ride API ***
    offerRide = (ride: Ride) => {
        const data = ride.asData()
        console.log(data)
        let ref: CollectionReference
        if (ride.repeatValue) {
            ref = collection(this.firestore, Collections.RecurringRides)
        } else {
            ref = collection(this.firestore, Collections.Rides)
        }
        return addDoc(ref, data)
    };

    // *** Cars data API
    //Inout optional user gets filled with cars if provided
    fetchCarsForUserId = (userId: string, user: User | undefined = undefined) => {
        const q = query(collection(this.firestore, Collections.Users, userId, Collections.Cars))
        return getDocs(q).then((querySnapshot: any) => {
            let cars: Car[] = []
            querySnapshot.forEach((doc: any) => {
                const car = Car.fromData(doc)
                if (car) {
                    cars.push(car)
                }
            })
            if (user)
                user.cars = cars
            return Promise.resolve(cars)
        })
    };

    deleteCarForUserId = (carId: string, userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId, Collections.Cars, carId)
        return deleteDoc(ref)
    };

    createCar = (car: any, userId: string) => {
        let ref = collection(this.firestore, Collections.Users, userId, Collections.Cars)
        return addDoc(ref, {
            timestampCreated:   serverTimestamp(),
            plateNum:           car.plateNum,
            brand:              car.brand,
            model:              car.model,
            color:              car.color,
            isElectric:         car.isElectric
        })
    };

    // *** Routes
    fetchRoutes = async (userId?: string, visibleCompanies?: string[]) => {
        const constraints: QueryConstraint[] = []

        if (userId) {
            constraints.push(
                where("userId", "==", userId)
            )
        }

        if (visibleCompanies) {
            constraints.push(
                where("company", "in", visibleCompanies)
            )
        }

        const q = query(collection(this.firestore, Collections.Routes),
                ...constraints
            )

        const snapshot = await getDocs(q)
        const routes = snapshot.docs.map(Route.fromData).filter(r => r).map(r => r!)

        return routes
    }

    fetchRoute = async (routeId: string) => {
        const ref = doc(this.firestore, Collections.Routes, routeId)
        const docSnapshot = await getDoc(ref)
        const route = Route.fromData(docSnapshot)
        if (!route) {
            throw Error("Failed to create route from data")
        }
        return route
    }

    fillRoutesDrivers = async (routes: Route[], includePrivate: boolean = false) => {
        const userIds = routes.map(route => route.userId)
        const usersMap = await this.fetchUsers(userIds, includePrivate)
        routes.forEach((route) => {
            route.driver = usersMap[route.userId]
        })
        return routes
    }

    updateRoute = async (routeId: string, data: {[key: string]: any}) => {
        const ref = doc(this.firestore, Collections.Routes, routeId)
        return updateDoc(ref, data)
    }

    // *** Matches
    fetchMatchesForRoute = async (route: Route, limitResults: number) => {
        const constraints: QueryConstraint[] = [
            where("passengerRouteId", "==", route.id),
            orderBy("passengerFavorite", "desc"),
            orderBy("mismatchRating", "asc")
        ]
        if (route.direction()) {
            constraints.push(where("direction", "==", route.direction))
        }
        constraints.push(limit(limitResults))
        const q = query(collection(this.firestore, Collections.Matches),
                ...constraints,
            )

        const snapshot = await getDocs(q)
        const matches = snapshot.docs.map(Match.fromData).filter(r => r).map(r => r!)

        return matches
    }

    fetchMatch = async (matchId: string) => {
        const ref = doc(this.firestore, Collections.Matches, matchId)
        const docSnapshot = await getDoc(ref)
        const match = Match.fromData(docSnapshot)
        if (!match) {
            throw Error(`Failed to fetch match id ${matchId}`)
        }
        return match
    }

    // *** Connections data API
    fetchPassengerConnections = (passengerId: string) => {
        const q = query(collection(this.firestore, Collections.Connections), where("passengerId", "==", passengerId))
        return getDocs(q).then(this.makeConnectionsFromSnapshot)
    };

    fetchDriverConnections = (driverId: string) => {
        const q = query(collection(this.firestore, Collections.Connections), where("driverId", "==", driverId))
        return getDocs(q).then(this.makeConnectionsFromSnapshot)
    };

    fetchRideConnections = (rideId: string) => {
        const q = query(collection(this.firestore, Collections.Connections), where("rideId", "==", rideId))
        return getDocs(q).then(this.makeConnectionsFromSnapshot)
    };

    fetchPassengerRideConnections = (passengerId: string, rideId: string) => {
        const q = query(collection(this.firestore, Collections.Connections), where("rideId", "==", rideId), where("passengerId", "==", passengerId))
        return getDocs(q).then(this.makeConnectionsFromSnapshot)
    };

    fetchAllConnections = (sinceDate?: Date) => {
        let constraints = []
        if (sinceDate) {
            constraints.push(where("rideTimestamp", ">", sinceDate))
        }
        const q = query(collection(this.firestore, Collections.Connections), ...constraints)
        return getDocs(q).then(this.makeConnectionsFromSnapshot)
    };

    makeConnectionsFromSnapshot = (querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map(Connection.fromData).filter(c => c).map(c => c!)
    };

    fillConnectionsPassengers = (connections: Connection[], includePrivate: boolean = false) => {
        const userIds = connections.map(connection => connection.passengerId)
        return this.fetchUsers(userIds, includePrivate).then(usersMap => {
            let resultConnections: Connection[] = []
            connections.forEach(connection => {
                const passenger = usersMap[connection.passengerId]
                if (passenger) {
                    connection.passenger = passenger
                    resultConnections.push(connection)
                }
            })
            return Promise.resolve(resultConnections)
        })
    };

    respondToConnection = (connectionId: string, accepted: boolean) => {
        const ref = doc(this.firestore, Collections.Connections, connectionId)
        return updateDoc(ref, {
            timestampUpdated: serverTimestamp(),
            accepted: accepted
        });
    };

    // Analytics
    fetchAnalyticsData = (fromDate: moment.Moment, toDate: moment.Moment, driverFilter: string) => {
        const apiAddress = process.env.REACT_APP_WEB_API + '/hmWebApi/v1'
        return axios.post(apiAddress + '/getAnalytics', {
                fromDate: fromDate.unix(),
                toDate: toDate.unix(),
                companyFilter: driverFilter
            }).then(response => {
                if (response.status === 200) {
                    return Promise.resolve(response.data)
                } else {
                    return Promise.reject(`Response status code  ${response.status}`)
                }
            }).catch(error => {
                console.log("Error fetching analytics data: ", error)

            })
    };

    // *** Slack ***
    updateSlackData = async (userId: string, accessToken: string, slackUserId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return updateDoc(ref, {
            slackData: {
                accessToken,
                userId: slackUserId
            }
        })
    }

    // Helper functions
    fetchMatchesForRoutes = async (routes: Route[]) => {
        const promises = routes.map(r => this.fetchMatchesForRoute(r, 20))
        const matches = (await Promise.all(promises)).flat()
        return matches
    }

    fetchRideInstancesForRoutes = async (routes: Route[], startDate: Date, endDate: Date) => {
        const rideInstancesPromises = routes.map(r => this.fetchRidesForRoute(r, startDate, endDate))
        return (await Promise.all(rideInstancesPromises)).flat()
    }

    expandAndFilterRoutes = (location: Location, routes: Route[], rideInstances: NewRide[], startDate: Date, endDate: Date) => {
        const expandedRidesPairs = routes.map(r => this.expandRoute(location, r, startDate, endDate)).flat()
        const expandedRides = expandedRidesPairs.map(pair => pair.flat()).flat()
        const newRides = [...rideInstances]
        for (const expandedRide of expandedRides) {
            // Add all expanded rides which don't already have an instance
            if (newRides.find(instance => expandedRide.routeId === instance.routeId && expandedRide.destDatetime.getTime() === instance.destDatetime.getTime()) === undefined) {
                newRides.push(expandedRide)
            }
        }
        return newRides.filter(r => !r.canceled)
    }

    expandAndFilterRoutesInPairs = (location: Location, routes: Route[], rideInstances: NewRide[], startDate: Date, endDate: Date) => {
        const expandedRidesPairs = routes.map(r => this.expandRoute(location, r, startDate, endDate)).flat()
        for (const pair of expandedRidesPairs) {
            for (const ride of [pair.forward, pair.backward].filterUndef()) {
                const rideInstance = rideInstances.find(instance => ride.routeId === instance.routeId && ride.destDatetime.getTime() === instance.destDatetime.getTime())
                if (rideInstance !== undefined) {
                    pair.addRide(rideInstance, false)
                }
            }
        }
        return expandedRidesPairs
    }
}

export default Firebase;
