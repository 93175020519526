import { DocumentSnapshot, Timestamp } from '../firestoreImports.js'
import { User } from './user.js'

export class Connection {
    constructor(
        public id:                string,
        public driverId:          string,
        public passengerId:       string,
        //public rideDatetime:      number, // Deprecated
        public rideId:            string,
        public rideTimestamp:     Date, // Legacy timestamp (always departure time)
        public destDatetime:      Date,
        public timestampCreated?: Date,
        public accepted?:         boolean,
        public canceled?:         boolean,
        public isRecurring?:      boolean,
        public passengerRouteId?: string,
        public matchId?:          string
    ){}

    passenger?: User

    static fromData(doc: DocumentSnapshot): Connection | null {
        if (!doc.data()) {
            console.warn(`Cannot create Connection ${doc.id} from snapshot`)
            return null
        }
        const data = doc.data()!

        let rideDate: Date
        if (data.rideTimestamp) {
            rideDate = (data.rideTimestamp as Timestamp).toDate()
        } else {  //Old iOS Carpool compatibility
            rideDate = new Date(data.rideDatetime * 1000)
        }

		const timestampCreated = data.timestampCreated as Timestamp | undefined
        const destDatetime = data.destDatetime as Timestamp | undefined

        return new Connection(
            doc.id,
            data.driverId,
            data.passengerId,
            data.rideId,
            rideDate,
            destDatetime?.toDate() ?? rideDate, // Last resort backup
            timestampCreated?.toDate(),
            data.accepted,
            data.canceled,
            data.isRecurring, //Deprecated - points to deprecated RideExceptions, Route instances are saved as Rides
            data.passengerRouteId,
            data.matchId
        )
    }


    //Creates new key value object to save new ride on firestore
    asData(): {[k: string]: any} {
        const data: {[k: string]: any} = {
            driverId:            this.driverId,
            passengerId:         this.passengerId,
            rideId:            this.rideId,
            rideTimestamp:         this.rideTimestamp
        }
        if (this.timestampCreated) {
            data.timestampCreated = this.timestampCreated
        }
        if (this.accepted) {
            data.accepted = this.accepted
        }
        if (this.canceled) {
            data.canceled = this.canceled
        }
        if (this.isRecurring) {
            data.isRecurring = this.isRecurring
        }
        if (this.destDatetime) {
            data.destDatetime = this.destDatetime
        }
        if (this.passengerRouteId) {
            data.passengerRouteId = this.passengerRouteId
        }
        if (this.matchId) {
            data.matchId = this.matchId
        }
        return data
    }
}