import React from 'react';
import moment from 'moment';
import { Unsubscribe, User as FIRUser } from 'firebase/auth';
import Firebase, { withFirebase, User } from 'components/Firebase';

interface JSBridge {
    postMessage(msg: string) : any;
}
declare global {
	interface Window {
		jsBridge?: JSBridge
		webkit?: any
	}
}

export enum UserState {
    Unknown = 0,
    NoUser,
    Auth,
    Broken,
    Deleted
};

const AuthUserContext = React.createContext<UserAuthInfo>({state: UserState.Unknown})

interface IWithAuthenticationProps {
	firebase: Firebase
}
interface IWithAuthenticationState {
	userState: UserState
	user?: User
}
export interface UserAuthInfo {
	state: UserState
	user?: User
	authUser?: FIRUser
	forceUpdate?: (userId: string) => Promise<void>
}

export const withAuthentication = (Component: typeof React.Component) => {
  	class WithAuthentication extends React.Component<IWithAuthenticationProps, IWithAuthenticationState> {
  		authUser?: FIRUser
  		listener?: Unsubscribe

	    constructor(props: IWithAuthenticationProps) {
			super(props);
			this.authUser = undefined
	      	this.state = {
	        	userState: UserState.Unknown,
	        	user: undefined
	    	};
	    }

	    componentDidMount() {
	        this.listener = this.props.firebase.onAuthStateChanged(authUser => {
	            if (authUser) {
	            	this.authUser = authUser
	            	this.updateUser(authUser.uid)
	            	this.sendMessageToWebView(JSON.stringify({"userId": authUser.uid}))
	            	//console.log("Auth user: ", authUser.emailVerified);
	            } else {
					this.authUser = undefined
					this.setState({ userState: UserState.NoUser, user: undefined })
	            }
	        });
	    }

	    componentWillUnmount() {
	        if (this.listener) {
	        	this.listener()
	        }
	    }

	    sendMessageToWebView = (msg: string) => {
	        window.webkit?.messageHandlers.jsMessageHandler.postMessage(msg)
			window.jsBridge?.postMessage(msg)
	    }

	    updateUser = (userId: string) => {
			this.setState({
				userState: UserState.Unknown,
				user: undefined
			})
        	return this.props.firebase.fetchUserDataWithCars(userId, true)
        	.then((user) => {
        		if (this.authUser && user) {
					//console.log("Updated user")
        			//user.forceUpdate = this.updateUser
        			//user.emailVerified = this.authUser.emailVerified
					this.props.firebase.setAuthLocale(user.locale)
        			if (!user.deleted) {
	        			this.setState({
	        				userState: UserState.Auth,
	        				user: user
	        			})
        			} else {
	        			this.setState({
	        				userState: UserState.Deleted,
	        				user: user
	        			})
        			}
					const lastActiveWeb = this.props.firebase.getUsersLastActiveTime(user)
					if (lastActiveWeb === undefined || moment(lastActiveWeb).isBefore(moment().subtract(10, 'm'))) {
						this.props.firebase.setUsersLastActiveTime(user.uid)
					}
        		} else {
        			this.setState({ userState: UserState.Broken })
        		}
        	}).catch((error: any) => {
    			console.log("Error fetching user: ", error);
    			this.setState({ userState: UserState.Broken })
			})
	    };

    	render() {
    		const authInfo: UserAuthInfo = {
	    	   			state: this.state.userState,
    					 user: this.state.user,
				     authUser: this.authUser,
			 	  forceUpdate: this.updateUser
			 	}
			return (
				<AuthUserContext.Provider value={authInfo}>
			  		<Component {...this.props} />
				</AuthUserContext.Provider>
			);
    	}
  	}

  	return withFirebase(WithAuthentication);
};

export const isiOSApp = () => {return window.webkit?.messageHandlers.jsMessageHandler !== undefined}
export const isAndroidApp = () => {return window.jsBridge !== undefined}
export const isInWebView = () => {return isiOSApp() || isAndroidApp()}

export default AuthUserContext;