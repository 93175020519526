import { DocumentSnapshot, Timestamp } from '../firestoreImports.js';

export enum EngineType {
	Ice = 0,
	Electric,
	NaturalGas
}

interface CarParameters {
	id: string
	plateNum: string
	timestampCreated?: Date
	brand?: string
	model?: string
	color?: string
	engineType?: EngineType
}
export class Car {
	id: string
	plateNum: string
	timestampCreated?: Date
	brand?: string
	model?: string
	color?: string
	engineType: EngineType
	isElectric: boolean

	constructor(parameters: CarParameters) {
		this.id = parameters.id
		this.plateNum = parameters.plateNum
		this.timestampCreated = parameters.timestampCreated
		this.brand = parameters.brand
		this.model = parameters.model
		this.color = parameters.color
		this.engineType = parameters.engineType ?? EngineType.Ice
		this.isElectric = parameters.engineType === EngineType.Electric
	}

	static fromData(doc: DocumentSnapshot): Car | null {
		if (!doc.data()) {
            console.warn("Cannot create Car from snapshot")
            return null
        }
		const data = doc.data()!
		const timestampCreated = data.timestampCreated as Timestamp
		const car = new Car({
			id: doc.id,
			plateNum: data.plateNum,
			timestampCreated: timestampCreated?.toDate(),
			brand: data.brand,
			model: data.model,
			color: data.color,
			engineType: data.engineType ?? (data.isElectric ? EngineType.Electric : EngineType.Ice)
		})
		return car
	}

    public toString = () : string => {
        return `${this.brand ?? ""} ${this.model ?? ""} (${this.plateNum})`
    }
}