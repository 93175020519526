import { DocumentSnapshot, Timestamp } from '../firestoreImports.js'
import { Car } from './car.js'
import { Location } from './location.js'

export enum UserPriorityLevel {
	Banned = -5,
	Restricted = -3,
	Basic = 0,
	Higher = 1,
	Favored = 4,
	Carpooling = 6,
	Priority = 7,
	Visitors = 8,
	Permanent = 9,
	Admin = 10
}
export namespace UserPriorityLevel {
	export function allValues() {
		return Object.keys(UserPriorityLevel).map(v => Number(v)).filter(v => !isNaN(v)).sort((a,b) =>a-b)
	}

	export function publicValues() {
		return allValues().filter(v => [-5, -3, 0, 1, 4, 7].includes(v))
	}

    export function toString(priority: UserPriorityLevel): string {
        return UserPriorityLevel[priority];
    }
}


export class User {
	uid: string
    timestampUpdated?: Date
    name: string
    surname: string
    phone?: string
    email: string
    company: string = "unknown"
    locationIds?: string[]
    activeLocationId: string = ""
	get activeLocation() {return this.locations?.at(0) }
    locale:  string = "en"
    deleted: boolean = false
    priority: UserPriorityLevel = UserPriorityLevel.Basic
	defaultCarId?: string
	slackData: any

	// Garage
	fcmPfToken?: string
	lastActiveGarageWeb?: Date
	visitorCompany?: string
	host?: string

	// Carpool
	fcmToken?: string
	lastActiveCarpoolWeb?: Date
	visibleCompanies: string[] = [this.company]

	// separately fetched data
	cars?: Car[]
	locations?: Location[]
	adminLocations: string[] = []
	adminCompanies: string[] = []

	constructor(uid: string, name: string, surname: string, email: string) {
		this.uid = uid
		this.name = name
		this.surname = surname
		this.email = email
	}

	static fromData(doc: DocumentSnapshot): User | null {
        const data = doc.data()
		if (!data) {
            console.warn("Cannot create User from snapshot ", doc.id)
            return null
        }
		const user = new User(doc.id, data.name ?? "", data.surname ?? "", data.email ?? "")
		user.timestampUpdated = data.timestampUpdated ? (data.timestampUpdated as Timestamp).toDate() : undefined
		user.priority = data.priority ?? UserPriorityLevel.Basic
		user.locationIds = data.locations
        user.phone = data.phone
        user.company = data.company ?? "unknown"
        user.locale = data.locale ?? "en"
        user.deleted = (data.deleted as boolean) ?? false
		user.fcmToken = data.fcmToken
		user.fcmPfToken = data.fcmPfToken
		user.defaultCarId = data.defaultCarId
		user.visitorCompany = data.visitorCompany
		user.host = data.host
		user.lastActiveGarageWeb = data.lastActiveGarageWeb ? (data.lastActiveGarageWeb as Timestamp).toDate() : undefined
		user.lastActiveCarpoolWeb = data.lastActiveCarpoolWeb ? (data.lastActiveCarpoolWeb as Timestamp).toDate() : undefined
		user.visibleCompanies = data.visibleCompanies ?? [user.company]
		if (user.visibleCompanies.length === 0) {
			user.visibleCompanies = [user.company]
		}

        if (!user.locationIds || user.locationIds.length === 0) {
        	user.locationIds = [user.company]
        }
        user.activeLocationId = user.locationIds[0]
		return user
	}
}